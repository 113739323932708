import { FAQ_ROUTING } from '@features/partners/builder/content/faq/faq.routes';
import { PLANS_ROUTING } from '@features/partners/builder/content/plans/routes';
import { CONTENT_ROUTING } from '@features/partners/builder/content/routes/content-routing';
import { SERVICES_ROUTING } from '@features/partners/builder/content/services/routes';
import { DASHBOARD_ROUTING } from '@features/partners/builder/pages/dashboard/routes';
import { HOMEPAGE_ROUTING } from '@features/partners/builder/pages/homepage/routes';
import {
  LEGAL_ROUTING,
  LegalMentionsPageTitlesValues,
} from '@features/partners/builder/pages/legals/routes/legals-routing';
import { ONBOARDING_ROUTING } from '@features/partners/builder/pages/onboarding/routes/onboarding-routing';
import { PAGES_ROUTING } from '@features/partners/builder/pages/routes';
import { UPSELL_ROUTING } from '@features/partners/builder/pages/upsell/routes/upsell-routing';
import { BUILDER_ROUTING } from '@features/partners/builder/routes';
import { MENU_ROUTING } from '@features/partners/builder/settings/menu/routes/menu.routes';
import { PLATFORM_ACCESS_ROUTING } from '@features/partners/builder/settings/platform-access/routes';
import { SETTINGS_ROUTING } from '@features/partners/builder/settings/routes';
import { MenuTab } from '@models/multiple-side-menu/multiple-side-menu';

const PAGE_TAB = {
  label: 'Pages',
  menu: [
    {
      label: 'Homepage',
      initialRoute: `${BUILDER_ROUTING.Pages}/${PAGES_ROUTING.Homepage}`,
      children: [
        {
          label: 'Infos de la homepage',
          initialRoute: `${BUILDER_ROUTING.Pages}/${PAGES_ROUTING.Homepage}/${HOMEPAGE_ROUTING.infos}`,
        },
        {
          label: 'Blocs',
          initialRoute: `${BUILDER_ROUTING.Pages}/${PAGES_ROUTING.Homepage}/${HOMEPAGE_ROUTING.blocks}`,
          disabledTooltipLabel: 'Activer la Homepage du Saas pour accéder à cette configuration',
        },
        {
          label: 'Témoignages',
          initialRoute: `${BUILDER_ROUTING.Pages}/${PAGES_ROUTING.Homepage}/${HOMEPAGE_ROUTING.testimonials}`,
          disabledTooltipLabel: 'Activer la Homepage du Saas pour accéder à cette configuration',
        },
        {
          label: 'FAQ',
          initialRoute: `${BUILDER_ROUTING.Pages}/${PAGES_ROUTING.Homepage}/${HOMEPAGE_ROUTING.faq}`,
          disabledTooltipLabel: 'Activer la Homepage du Saas pour accéder à cette configuration',
        },
      ],
    },
    {
      label: 'Dashboard',
      initialRoute: `${BUILDER_ROUTING.Pages}/${PAGES_ROUTING.Dashboard}`,
      children: [
        {
          label: 'Messages temporaire',
          initialRoute: `${BUILDER_ROUTING.Pages}/${PAGES_ROUTING.Dashboard}/${DASHBOARD_ROUTING.TemporaryMessage}`,
        },
        {
          label: 'Module thème',
          initialRoute: `${BUILDER_ROUTING.Pages}/${PAGES_ROUTING.Dashboard}/${DASHBOARD_ROUTING.ThemeModule}`,
        },
      ],
    },
    {
      label: 'Onboarding',
      initialRoute: `${BUILDER_ROUTING.Pages}/${PAGES_ROUTING.Dashboard}`,
      children: [
        {
          label: 'Étapes',
          initialRoute: `${BUILDER_ROUTING.Pages}/${PAGES_ROUTING.Onboarding}/${ONBOARDING_ROUTING.Steps}`,
        },
        {
          label: 'Visuel de présentation',
          initialRoute: `${BUILDER_ROUTING.Pages}/${PAGES_ROUTING.Onboarding}/${ONBOARDING_ROUTING.Presentation}`,
        },
      ],
    },
    {
      label: 'Liste des services',
      initialRoute: `${BUILDER_ROUTING.Pages}/${PAGES_ROUTING.ServicesList}`,
      children: [],
    },
    {
      label: 'Produits financiers',
      children: [],
      initialRoute: `${BUILDER_ROUTING.Pages}/${PAGES_ROUTING.BankingProductsPage}`,
      disabledTooltipLabel: 'Aucun produit financier de configuré',
      disablingKey: 'bankingProductPage',
    },
    {
      label: 'Upsell',
      initialRoute: `${BUILDER_ROUTING.Pages}/${PAGES_ROUTING.Upsell}`,
      disabledTooltipLabel: "Les plans configurés ne permettent pas l'upsell",
      disablingKey: 'upsellPage',
      children: [
        {
          label: 'Présentation formule',
          initialRoute: `${BUILDER_ROUTING.Pages}/${PAGES_ROUTING.Upsell}/${UPSELL_ROUTING.Presentation}`,
        },
        {
          label: "Méthodes d'Upsell",
          initialRoute: `${BUILDER_ROUTING.Pages}/${PAGES_ROUTING.Upsell}/${UPSELL_ROUTING.Methods}`,
        },
      ],
    },
    {
      label: 'Légales',
      initialRoute: `${BUILDER_ROUTING.Pages}/${PAGES_ROUTING.Legals}`,
      children: [
        {
          label: LegalMentionsPageTitlesValues['accessibility'],
          initialRoute: `${BUILDER_ROUTING.Pages}/${PAGES_ROUTING.Legals}/${LEGAL_ROUTING.ACCESSIBILITY}`,
        },
        {
          label: LegalMentionsPageTitlesValues['cgu'],
          initialRoute: `${BUILDER_ROUTING.Pages}/${PAGES_ROUTING.Legals}/${LEGAL_ROUTING.CGU}`,
        },
        {
          label: LegalMentionsPageTitlesValues['cgv'],
          initialRoute: `${BUILDER_ROUTING.Pages}/${PAGES_ROUTING.Legals}/${LEGAL_ROUTING.CGV}`,
          disabledTooltipLabel: 'Vous devez configurer un paiement',
          disablingKey: 'cgv',
        },
        {
          label: LegalMentionsPageTitlesValues['cookies'],
          initialRoute: `${BUILDER_ROUTING.Pages}/${PAGES_ROUTING.Legals}/${LEGAL_ROUTING.COOKIES}`,
        },
        {
          label: LegalMentionsPageTitlesValues['confidentialite'],
          initialRoute: `${BUILDER_ROUTING.Pages}/${PAGES_ROUTING.Legals}/${LEGAL_ROUTING.CONFIDENTIALITE}`,
        },
        {
          label: LegalMentionsPageTitlesValues['mentions-legales'],
          initialRoute: `${BUILDER_ROUTING.Pages}/${PAGES_ROUTING.Legals}/${LEGAL_ROUTING.MENTIONS_LEGALES}`,
        },
        {
          label: LegalMentionsPageTitlesValues['legal-partners'],
          initialRoute: `${BUILDER_ROUTING.Pages}/${PAGES_ROUTING.Legals}/${LEGAL_ROUTING.PARTNERS}`,
        },
      ],
    },
    {
      label: 'FAQ',
      children: [],
      initialRoute: `${BUILDER_ROUTING.Pages}/${PAGES_ROUTING.FAQ}`,
    },
    {
      label: 'Contact',
      children: [],
      initialRoute: `${BUILDER_ROUTING.Pages}/${PAGES_ROUTING.Contact}`,
    },
  ],
} as const satisfies MenuTab;

const CONTENT_TAB = {
  label: 'Contenus',
  menu: [
    {
      label: 'Plans',
      initialRoute: `${BUILDER_ROUTING.Content}/${CONTENT_ROUTING.Plans}`,
      children: [
        {
          label: 'Plans et groupes de plans',
          initialRoute: `${BUILDER_ROUTING.Content}/${CONTENT_ROUTING.Plans}/${PLANS_ROUTING.PlansGroups}`,
        },
        {
          label: 'Renouvellement',
          initialRoute: `${BUILDER_ROUTING.Content}/${CONTENT_ROUTING.Plans}/${PLANS_ROUTING.Renewal}`,
          disablingKey: 'renewal',
        },
      ],
    },
    {
      label: 'Thèmes',
      children: [],
      initialRoute: `${BUILDER_ROUTING.Content}/${CONTENT_ROUTING.Themes}`,
    },
    {
      label: 'Moments de vie',
      children: [],
      initialRoute: `${BUILDER_ROUTING.Content}/${CONTENT_ROUTING.LifeMoments}`,
    },
    {
      label: 'Services',
      initialRoute: `${BUILDER_ROUTING.Content}/${CONTENT_ROUTING.Services}`,
      children: [
        {
          label: 'Infos du service',
          initialRoute: `${BUILDER_ROUTING.Content}/${CONTENT_ROUTING.Services}/${SERVICES_ROUTING.displayConfig}/{{dynamicKey}}`,
          dynamicKey: 'serviceId',
          displayOptions: {
            regexp: /\/services\/(?!.*create)[^?]*\/.+/,
            isDisplayed: true,
          },
        },
        {
          label: 'Gestion des onglets',
          initialRoute: `${BUILDER_ROUTING.Content}/${CONTENT_ROUTING.Services}/${SERVICES_ROUTING.tabs}/{{dynamicKey}}`,
          dynamicKey: 'serviceId',
          displayOptions: {
            regexp: /\/services\/(?!.*create)[^?]*\/.+/,

            isDisplayed: true,
          },
        },
        {
          label: 'Consentements',
          initialRoute: `${BUILDER_ROUTING.Content}/${CONTENT_ROUTING.Services}/${SERVICES_ROUTING.consent}/{{dynamicKey}}`,
          dynamicKey: 'serviceId',
          displayOptions: {
            regexp: /\/services\/(?!.*create)[^?]*\/.+/,
            isDisplayed: true,
          },
        },
        {
          label: 'Avantages',
          initialRoute: `${BUILDER_ROUTING.Content}/${CONTENT_ROUTING.Services}/${SERVICES_ROUTING.benefit}/{{dynamicKey}}`,
          dynamicKey: 'serviceId',
          displayOptions: {
            regexp: /\/services\/(?!.*create)[^?]*\/.+/,
            isDisplayed: true,
          },
        },
      ],
    },
    {
      label: 'Communications',
      children: [],
      initialRoute: `${BUILDER_ROUTING.Content}/${CONTENT_ROUTING.Communications}`,
    },
    {
      label: 'Produits financiers',
      children: [],
      initialRoute: `${BUILDER_ROUTING.Content}/${CONTENT_ROUTING.BankingProducts}`,
    },
    {
      label: 'FAQ',
      initialRoute: `${BUILDER_ROUTING.Content}/${CONTENT_ROUTING.FAQ}`,
      children: [
        {
          label: 'Questions',
          initialRoute: `${BUILDER_ROUTING.Content}/${CONTENT_ROUTING.FAQ}/${FAQ_ROUTING.Questions}`,
        },
        {
          label: 'Catégories',
          initialRoute: `${BUILDER_ROUTING.Content}/${CONTENT_ROUTING.FAQ}/${FAQ_ROUTING.Categories}`,
        },
      ],
    },
  ],
} as const satisfies MenuTab;

const SETTINGS_TAB = {
  label: 'Paramètres',
  menu: [
    {
      label: 'Branding',
      children: [
        {
          label: 'Naming & logos',
          initialRoute: `${BUILDER_ROUTING.Settings}/${SETTINGS_ROUTING.Branding}`,
          anchor: 'naming-logos',
        },
        {
          label: 'Palette de couleur',
          initialRoute: `${BUILDER_ROUTING.Settings}/${SETTINGS_ROUTING.Branding}`,
          anchor: 'plate-color',
        },
      ],
    },
    {
      label: `${SETTINGS_ROUTING.Menu}`,
      children: [
        {
          label: `${MENU_ROUTING.DESKTOP}`,
          initialRoute: `${BUILDER_ROUTING.Settings}/${SETTINGS_ROUTING.Menu}/${MENU_ROUTING.DESKTOP}`,
        },
        {
          label: `${MENU_ROUTING.MOBILE}`,
          initialRoute: `${BUILDER_ROUTING.Settings}/${SETTINGS_ROUTING.Menu}/${MENU_ROUTING.MOBILE}`,
        },
      ],
    },
    {
      label: 'Méthodes de connexion',
      initialRoute: `${BUILDER_ROUTING.Settings}/${SETTINGS_ROUTING.SignInMethods}`,
      children: [],
    },
    {
      label: 'Accès à la plateforme',
      initialRoute: `${BUILDER_ROUTING.Settings}/${SETTINGS_ROUTING.PlatformAccess}`,
      children: [
        {
          label: 'Archiver la plateforme',
          initialRoute: `${BUILDER_ROUTING.Settings}/${SETTINGS_ROUTING.PlatformAccess}/${PLATFORM_ACCESS_ROUTING.Archive}`,
        },
        {
          label: 'Co-plateforme',
          initialRoute: `${BUILDER_ROUTING.Settings}/${SETTINGS_ROUTING.PlatformAccess}/${PLATFORM_ACCESS_ROUTING.CoPlatform}`,
        },
      ],
    },
    {
      label: 'NPS',
      initialRoute: `${BUILDER_ROUTING.Settings}/${SETTINGS_ROUTING.Nps}`,
      children: [],
    },
    {
      label: 'Widget',
      initialRoute: `${BUILDER_ROUTING.Settings}/${SETTINGS_ROUTING.Widget}`,
      children: [],
    },
    {
      label: 'Support',
      initialRoute: `${BUILDER_ROUTING.Settings}/${SETTINGS_ROUTING.Support}`,
      children: [],
    },
    {
      label: 'Trackers',
      initialRoute: `${BUILDER_ROUTING.Settings}/${SETTINGS_ROUTING.Trackers}`,
      children: [],
    },
    {
      label: 'Versions',
      initialRoute: `${BUILDER_ROUTING.Settings}/${SETTINGS_ROUTING.Versions}`,
      children: [],
    },
  ],
} as const satisfies MenuTab;

export const BUILDER_MENU_CONFIG = [PAGE_TAB, CONTENT_TAB, SETTINGS_TAB];
